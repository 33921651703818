<template>
  <section
    class="editor"
    :class="currentSide === 'front' ? 'front-side' : 'back-side'"
  >
    <main :class="flipPhase" ref="editorMain">
      <GraphicPlacer
        v-for="graphic in graphics"
        :key="graphic.name"
        :graphic="graphic"
        :editorSide="currentSide"
        @flipSide="flip"
      ></GraphicPlacer>

      <img :src="template" id="template" />

      <img
        :style="{
          'mask-image': ` linear-gradient(to bottom, #fffc 25%, #fffc 75%), url(${mask})`
        }"
        class="mask"
        :src="template"
        v-show="currentTemplate !== 'custom'"
      />
      <div
        class="mask"
        v-for="i in 8"
        :key="'mask' + i"
        v-show="currentTemplate !== 'custom'"
      ></div>
    </main>
    <footer ref="flipButton">
      <button
        class="flip-apparel"
        @click="flip"
        v-show="currentTemplate !== 'custom'"
      >
        Flip Apparel
        <img
          :src="
            currentSide === 'front'
              ? require('@/assets/icons/flip_to_back.png')
              : require('@/assets/icons/flip_to_front.png')
          "
        />
      </button>
      <div class="side-label" v-show="currentTemplate !== 'custom'">
        {{ sideLabel }}
      </div>
    </footer>
  </section>
</template>

<style lang="less" scoped>
.editor {
  main {
    display: grid;
    grid-template-rows: 20px auto 20px;
    grid-template-columns: 20px auto 20px;
    height: calc(100% - 44px);

    &.flip-start {
      animation: 400ms flip-in cubic-bezier(0.47, 0, 0.745, 0.715);
    }
    &.flip-end {
      animation: 600ms flip-out cubic-bezier(0.215, 0.61, 0.355, 1);
    }

    img {
      grid-row: 2;
      grid-column: 2;
      width: 100%;
      height: 100%;
      margin: auto;
      object-fit: contain;
    }

    .mask {
      z-index: 2;
      pointer-events: none;
    }
    img.mask {
      background-color: white;
      transition: 0.5s background-color ease-out;
      mask-size: contain;
      mask-position: center;
      mask-repeat: no-repeat;
      mask-composite: subtract;
      -webkit-mask-composite: source-out;
    }
    div.mask {
      background-color: #fffc;

      &:nth-child(4) {
        grid-column: 1;
        grid-row: 1;
      }
      &:nth-child(5) {
        grid-column: 2;
        grid-row: 1;
      }
      &:nth-child(6) {
        grid-column: 3;
        grid-row: 1;
      }
      &:nth-child(7) {
        grid-column: 1;
        grid-row: 2;
      }
      &:nth-child(8) {
        grid-column: 3;
        grid-row: 2;
      }
      &:nth-child(9) {
        grid-column: 1;
        grid-row: 3;
      }
      &:nth-child(10) {
        grid-column: 2;
        grid-row: 3;
      }
      &:nth-child(10) {
        grid-column: 3;
        grid-row: 3;
      }
    }
  }

  footer {
    position: relative;
    grid-column: ~"1 / 4";
    grid-row: 4;
    display: flex;
    z-index: 10;
    font-size: 30px;
    height: 40px;

    padding: 0 10px;
    background-color: #adadb9;
    color: #5f3032;
    border-top: 4px solid;

    .flip-apparel {
      background-color: #003fd4;
      font-size: 20px;
      height: 34px;
      margin: auto 0;
      padding: 0 15px;

      img {
        float: right;
        margin-left: 0.5em;
      }
    }
    .side-label {
      margin-left: auto;
      font-weight: 700;
      line-height: 40px;
      color: black;
    }
  }
}

@keyframes flip-in {
  0% {
    transform: rotateY(0deg);
  }
  100% {
    transform: rotateY(90deg);
  }
}
@keyframes flip-out {
  0% {
    transform: rotateY(-90deg);
  }
  100% {
    transform: rotateY(0deg);
  }
}
</style>

<script>
const GraphicPlacer = () => import("@/components/GraphicPlacer.vue");

import { setTimeout } from "timers";

export default {
  components: {
    GraphicPlacer: GraphicPlacer
  },

  data() {
    return {
      currentSide: "front",
      flipPhase: "",
      graphicsLength: 0,
      templates: {
        tShirt: {
          imageFront: require("@/assets/apparel_templates/tshirt-front.svg"),
          imageBack: require("@/assets/apparel_templates/tshirt-back.svg"),
          maskFront: require("@/assets/apparel_templates/tshirt_mask-front.svg"),
          maskBack: require("@/assets/apparel_templates/tshirt_mask-back.svg")
        },
        polo: {
          imageFront: require("@/assets/apparel_templates/polo-front.svg"),
          imageBack: require("@/assets/apparel_templates/polo-back.svg"),
          maskFront: require("@/assets/apparel_templates/polo_mask-front.svg"),
          maskBack: require("@/assets/apparel_templates/polo_mask-back.svg")
        },
        lsShirt: {
          imageFront: require("@/assets/apparel_templates/lsShirt-front.svg"),
          imageBack: require("@/assets/apparel_templates/lsShirt-back.svg"),
          maskFront: require("@/assets/apparel_templates/lsShirt_mask-front.svg"),
          maskBack: require("@/assets/apparel_templates/lsShirt_mask-back.svg")
        },
        hoodie: {
          imageFront: require("@/assets/apparel_templates/hoodie-front.svg"),
          imageBack: require("@/assets/apparel_templates/hoodie-back.svg"),
          maskFront: require("@/assets/apparel_templates/hoodie_mask-front.svg"),
          maskBack: require("@/assets/apparel_templates/hoodie_mask-back.svg")
        },
        crewneck: {
          imageFront: require("@/assets/apparel_templates/crewneck-front.svg"),
          imageBack: require("@/assets/apparel_templates/crewneck-back.svg"),
          maskFront: require("@/assets/apparel_templates/crewneck_mask-front.svg"),
          maskBack: require("@/assets/apparel_templates/crewneck_mask-back.svg")
        },
        hat: {
          imageFront: require("@/assets/apparel_templates/hat-front.svg"),
          imageBack: require("@/assets/apparel_templates/hat-back.svg"),
          maskFront: require("@/assets/apparel_templates/hat_mask-front.svg"),
          maskBack: require("@/assets/apparel_templates/hat_mask-back.svg")
        },
        tote: {
          imageFront: require("@/assets/apparel_templates/tote.svg"),
          imageBack: require("@/assets/apparel_templates/tote.svg"),
          maskFront: require("@/assets/apparel_templates/tote_mask.svg"),
          maskBack: require("@/assets/apparel_templates/tote_mask.svg")
        },
        custom: {
          imageFront: require("@/assets/apparel_templates/other.svg"),
          imageBack: require("@/assets/apparel_templates/other.svg"),
          maskFront: require("@/assets/apparel_templates/other.svg"),
          maskBack: require("@/assets/apparel_templates/other.svg")
        }
      }
    };
  },

  computed: {
    graphics: {
      get() {
        return this.$store.getters.graphics;
      }
    },
    sideLabel() {
      return `${this.currentSide === "front" ? "Front" : "Back"}`;
    },

    template() {
      if (this.currentSide === "front") {
        return this.templates[this.currentTemplate].imageFront;
      } else {
        return this.templates[this.currentTemplate].imageBack;
      }
    },

    mask() {
      if (this.currentSide === "front") {
        return this.templates[this.currentTemplate].maskFront;
      } else {
        return this.templates[this.currentTemplate].maskBack;
      }
    },

    currentTemplate() {
      return this.$store.getters.apparel;
    }
  },

  watch: {
    currentTemplate(newValue, oldValue) {
      if (newValue === "custom") {
        this.currentSide = "front";
      }
    }
  },

  methods: {
    flip() {
      this.flipPhase = "flip-start";

      this.$refs.editorMain.addEventListener(
        "animationend",
        () => {
          this.currentSide = this.currentSide === "front" ? "back" : "front";
          this.flipPhase = "flip-end";

          this.$refs.editorMain.addEventListener(
            "animationend",
            () => {
              this.flipPhase = "flipPhase";
            },
            { once: true }
          );
        },
        { once: true }
      );
    }
  },

  mounted() {
    this.$store.subscribe((mutation, state) => {
      if (mutation.type === "addGraphic") {
        this.$store.commit("setNewGraphicSide", this.currentSide);
      }
    });
  }
};
</script>
